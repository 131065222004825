















































































































































































































































































import Vue from "vue";
import _ from "lodash";

import store from "../../store/shop";
import ShopAPI from "../../common/api/api_shop";
import notificationMixin from "../../mixin/notification";
import { Order } from "../../model/entity/order";
import { OrderStatus } from "../../model/entity/orderSetting";
import { ServedCode } from "../../model/entity/orderSetting";
import { TranslateServiceBuilder } from "../../services/Translate/translateServiceBuilder";

const translate = TranslateServiceBuilder.Instance()._;
const api = new ShopAPI();

const businessLogic = {
  noPayment: function (orders) {
    let rtn = [];
    for (const row of orders) {
      if (row.status === OrderStatus.STATUS_ORDERED) {
        rtn.push(row);
      }
    }
    return rtn;
  },
};

export default Vue.component("History", {
  components: {
    QButton: () => import("../../components/elements/QButton.vue"),
    SHHistoryCardSkeleton: () =>
      import("../../components/components/SHHistoryCardSkeleton.vue"),
    QTranslate: () => import("../../components/elements/QTranslate.vue"),
    QCartListTotal: () => import("../../components/elements/QCartListTotal.vue"),
  },
  mixins: [notificationMixin],
  data() {
    return {
      item: {},
      tableSessionId: this.$route.params.table_session_id,
      modal: {
        data: {
          order: {},
          payment: {
            id: "",
            orders: [],
            total_price: 0,
            staff_id: "",
            payment_details: [
              {
                type: "",
                cash_price: 0,
                change_price: 0,
              },
              {
                type: "",
                cash_price: 0,
                change_price: 0,
              }
            ],
          },
          menu_options: undefined,
        },
        errors: {
          order: {},
          payment: {},
        },
      },

      errors: "",
      mode: "",
      isLoading: false,
      taxes: [8, 10],

      staffId: store.state.staff.id,
      langKey: store.state.langKey,
      forceReload: false,
    };
  },
  mounted() {
    api.set_token(store.state.token);
    this.fetchOrders();
  },
  watch: {
    changeOrder(newVal, oldVal) {
      console.log(store.state.pageChange.value)
      console.log(this.tableSessionId);
      if (newVal != '' && store.state.pageChange.value === this.tableSessionId) {
        console.log('changeOrder');
        this.forceReload = true;
        this.fetchOrders();
      }
    }
  },
  computed: {
    prefix() {
      return this.$route.params.prefix;
    },
    shoppix() {
      return this.$route.params.shoppix;
    },
    hasTmpOrders: function () {
      if (!Object.keys(this.item).length) {
        return false;
      }
      return this.item.tmp_orders && this.item.tmp_orders.length > 0;
    },
    hasNoPaymentOrder: function () {
      if (!Object.keys(this.item).length) {
        return false;
      }
      let orders = this.item.no_paid_orders;
      return businessLogic.noPayment(orders).length > 0;
    },
    hasPayments: function () {
      if (!Object.keys(this.item).length) {
        return false;
      }
      return this.item.payments.length > 0;
    },
    isModalEdit(): boolean {
      return this.mode === "edit";
    },
    changeOrder() {
      return store.state.pageChange.order;
    }
  },
  methods: {
    // Header
    setTableSession() {
      if (!this.item) {
        return;
      }
      store.commit("setTableSession", this.item);
    },

    handleClickCancelTableSession: function () {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.callCancelTableSession();
    },
    handleClickModifyOrder: function (item) {
      if (this.isLoading) {
        return;
      }
      if (item.status !== OrderStatus.STATUS_ORDERED) {
        return;
      }
      if (item.is_served === ServedCode.SERVED_DONE) {
        return;
      }

      this.mode = "edit";
      this.modal.data.menu_options = item.menu.menu_options;
      this.modal.data.order = _.cloneDeep(item);
    },
    handleClickSubmitModify() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.submitModify();
    },
    handleClickCloseModal() {
      if (this.isLoading) {
        return;
      }

      this.closeModal();
    },
    handleClickSubmitDelete() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.submitDelete();
    },
    handleClickIncrease() {
      if (this.isLoading) {
        return;
      }

      this.updateQuantity(+1);
    },
    handleClickDecrease() {
      if (this.isLoading) {
        return;
      }

      this.updateQuantity(-1);
    },
    handleClickStatus(item) {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;
      if (item.is_served === ServedCode.SERVED_ORDER || item.is_served === ServedCode.SERVED_READY) {
        item.is_served = ServedCode.SERVED_DONE;
      } else if (item.is_served === ServedCode.SERVED_DONE) {
        item.is_served = ServedCode.SERVED_ORDER;
      }
      this.modal.data.order = _.cloneDeep(item);
      this.submitServed();
    },

    closeModal: function () {
      this.mode = "";
      this.isLoading = false;
      this.modal.data.order = {};
      this.modal.data.payments = {};
    },
    redirectToHome() {
      const prefix = this.$route.params.prefix;
      const shoppix = this.$route.params.shoppix;

      const base = `/${prefix}/${shoppix}`;

      if (store.getters.canUseEatIn) {
        this.$router.push(`${base}/home`);
      } else if (store.getters.canUseTakeout) {
        this.$router.push(`${base}/takeout`);
      }
    },

    updateQuantity(offset) {
      const quantity = Math.max(1, this.modal.data.order.quantity + offset);
      this.modal.data.order.quantity = quantity;
    },
    optionValueRadio(option_menu) {
      const result = _.find(this.modal.data.order.options, function (row: any) {
        if (!row.menus_menu_option) {
          return false;
        }
        return option_menu.id === row.menus_menu_option.id;
      });
      return result ? true : false;
    },
    optionValueCheckbox(option_menu) {
      const result = _.findIndex(
        this.modal.data.order.options,
        function (row: any) {
          return option_menu.id === row.menus_menu_option_id;
        }
      );
      return result !== -1;
    },
    optionChangeRadio(event, option) {
      _.remove(this.modal.data.order.options, function (row: any) {
        if (!row.menus_menu_option) {
          return false;
        }
        return option.id === row.menus_menu_option.menu_option_id;
      });
      this.modal.data.order.options.push({
        menus_menu_option_id: _.clone(event.target.value),
        menus_menu_option: {
          menu_option_id: option.id,
        },
        quantity: this.modal.data.order.quantity,
      });
    },
    optionChangeCheckbox(event, option) {
      if (!event.target.checked) {
        const result = _.findIndex(
          this.modal.data.order.options,
          function (row: any) {
            return event.target.value === row.menus_menu_option_id;
          }
        );
        if (result !== -1) {
          this.modal.data.order.options.splice(result, 1);
        }
      } else {
        this.modal.data.order.options.push({
          menus_menu_option_id: _.clone(event.target.value),
          menus_menu_option: {
            menu_option_id: option.id,
          },
          quantity: this.modal.data.order.quantity,
        });
      }
    },
    submitModify: function () {
      let message = "";
      api
        .order_edit({
          datas: { order: this.modal.data.order, staff: this.staffId },
        })
        .then((response) => {
          message = response.data.message;
          this.showSuccessNotification(message);
          this.closeModal();
          this.fetchOrders();
        })
        .catch((err) => {
          message =
            err.response.data.message ||
            translate("hall-0046", "保存に失敗しました。");
          this.showErrorNotification(message);
          this.modal.errors.order = message;
          this.isLoading = false;
        });
    },
    submitServed: function () {
      let message = "";
      api
        .order_served({
          datas: { order: this.modal.data.order },
        })
        .then((response) => {
          this.closeModal();
        })
        .catch((err) => {
          message =
            err.response.data.message ||
            translate("hall-0046", "保存に失敗しました。");
          this.showErrorNotification(message);
          this.modal.errors.order = message;
          this.isLoading = false;
        });
    },
    submitDelete: function () {
      let message = "";
      api
        .order_delete({ order: this.modal.data.order })
        .then((response) => {
          message = response.data.message;
          this.showSuccessNotification(message);
          this.closeModal();
          this.fetchOrders();
        })
        .catch((err) => {
          message =
            err.response.data.message ||
            translate("hall-0046", "保存に失敗しました。");
          this.showErrorNotification(message);
          this.modal.errors.order = message;
          this.isLoading = false;
        });
    },

    callCancelTableSession: function () {
      let message = "";
      api
        .table_sessions_cancel({ table_session_id: this.tableSessionId })
        .then((response) => {
          message = this.hasPayments ? "座席を精算済みにしました。" : "受付をキャンセルしました。";
          this.showSuccessNotification(message);
          this.closeModal();
          this.redirectToHome();
        })
        .catch((err) => {
          message =
            err.response.data.message ||
            translate("hall-0046", "保存に失敗しました。");
          this.showErrorNotification(message);
          this.errors = message;
          this.isLoading = false;
        });
    },

    fetchOrders() {
      !this.forceReload && (this.isLoading = true);
      let message = "";
      api
        .hallHistory({
          lang: this.langKey,
          table_session_id: this.tableSessionId,
        })
        .then((response) => {
          if (response.data.status !== "success") {
            message =
              response.data.message ||
              translate("hall-0048", "データの取得に失敗しました。");
            this.showErrorNotification(message);
            this.errors = message;
            return;
          }
          this.isLoading = false;
          this.item = response.data.data.tableSession;
          this.setTableSession();
        })
        .catch((err) => {
          message =
            err.response.data.message ||
            translate("hall-0048", "データの取得に失敗しました。");
          this.showErrorNotification(message);
          this.errors = message;
        }).finally(() => {
          this.isLoading = false;
          this.forceReload = false;
        });
    },
  },
  filters: {
    numberFormat(number) {
      if (typeof number !== "number") {
        return number;
      }
      return number.toLocaleString();
    },
    alcoholIcon(value) {
      return value ? "on" : "";
    },
    statusIcon(order) {
      if (order.status === OrderStatus.STATUS_CANCELED) {
        return "cancel";
      }
      if (order.status === OrderStatus.STATUS_PAID) {
        return "paid";
      }
      if (order.is_served === ServedCode.SERVED_DONE) {
        return "serving";
      }
      return "";
    },
    taxType(flg: boolean): string {
      if (flg == true) {
        return " (" + translate("hall-0096", "税込") + ")";
      }
      if (flg == false) {
        return " (" + translate("hall-0097", "税抜") + ")";
      }
      return;
    },
    taxPrice(prices): number {
      if (prices.tanka.exclude !== null) {
        return prices.tanka.exclude;
      }
      return prices.tanka.include;
    },
    taxTotal(prices): number {
      if (prices.total.exclude !== null) {
        return prices.total.exclude;
      }
      return prices.total.include;
    },
  },
});
